import flat from 'flat';
import {
  AuditAction,
  AuditActionResult,
  ActivityType,
  PersonAccessRightStatus
} from '../../Apollo';
import { ApiKeyEventType } from '../../Apollo/fragments/apikey/ApiKeyEvent';
import TProfileManagerMessages from '../TProfileManagerMessages';
import { DomainValidationMode } from 'Apollo/fragments/organization/DomainValidationMode';

export default flat<TProfileManagerMessages, { [messageId: string]: string }>({
  applicationName: 'Gestion des comptes',
  common: {
    action: {
      loading: 'Chargement...',
      search: 'Recherche',
      subscribe: 'Abonner',
      accept: 'Accepter',
      confirm: 'Confirmer',
      deny: 'Refuser',
      add: 'Ajouter',
      delete: 'Supprimer',
      edit: 'Editer',
      back: 'précédent',
      export: 'Exporter',
      finish: 'Terminer',
      previous: 'Précédent',
      next: 'suivant',
      cancel: 'Annuler',
      reset: 'Réinitialiser',
      toEnd: 'Terminer',
      agree: "D'accord",
      retry: 'Réessayer',
      understand: "J'ai compris",
      moreDetails: 'Plus de détails',
      validate: 'Valider',
      migrate: 'Migrer',
      open: 'Ouvrir',
      close: 'Fermer',
      ignore: 'Ignorer',
      clear: 'Effacer la recherche',
      yes: 'Oui',
      no: 'Non',
      ok: 'Ok',
      backToHomePage: `Retour à l'accueil`,
      activate: 'Activer',
      deactivate: 'Désactiver',
      doNotModify: 'Ne pas modifier',
      seeProfile: 'Voir le profil',
      login: 'Se connecter',
      copyCode: 'Copier le code',
      see: {
        seeMore: 'Voir plus',
        seeDescription: 'Voir la description',
        seeExpirationDate: "Voir la date d'expiration",
        seeGroupDetails: 'Voir le détail du groupe',
        seeTheList: 'Voir la liste'
      }
    },
    error: {
      disabledResult: 'Résultat indisponible',
    },
    date: {
      editExpirationDate: `Editer la date d'expiration`,
      expireDetails: 'Expire le {date} - {hour}'
    },
    person: {
      role: {
        adm: 'Administrateur',
        superAdm: 'Super administrateur',
        superAdmSentence: 'Vous naviguez avec les droits super administrateur',
        superReaderSentence: 'Vous naviguez avec les droits super lecteur',
        admSuperReader: 'Administrateur / Super-lecteur',
        reader: 'Lecteur',
        superReader: 'Super-lecteur',
        user: 'Utilisateur'
      }
    },
    service: {
      unavailable: 'Service indisponible',
      access: 'Accès'
    },
    terminology: {
      accessRights: "Droits d'accès",
      alert: {
        info: 'Information'
      },
      client: 'Compte client',
      status: 'Statut',
      active: 'Actif',
      inactive: 'Inactif',
      account: 'Compte client',
      accounts: 'Comptes clients',
      description: 'Description',
      codeGroup: 'Code de groupe',
      famillies: 'familles',
      groups: 'groupes',
      localAccount: 'Compte local',
      menu: 'Menu',
      MSaccount: 'Compte Microsoft',
      GoogleAccount: 'Compte Google',
      required: 'Obligatoire',
      services: 'services',
      tutorial: 'Tutoriel',
      immutableId: 'Immutable Id',
      email: 'Adresse e-mail',
      or: 'ou',
      groupName: 'Nom du groupe',
      yes: 'Oui',
      no: 'Non'
    },
    dates: {
      creationDate: 'Date de création : {value}',
      today: "Aujourd'hui",
      yesterday: 'Hier',
      previously: 'Précédemment',
      thisInstant: 'il y a 0 seconde',
      jours: 'jours'
    },
    label: {
      id: 'ID : ',
      page: 'Page',
      rowPerPage: 'Résultats / page',
      name: 'Nom'
    },
    pagination: {
      firstPage: 'Première page',
      lastPage: 'Dernière page',
      previousPage: 'Page précédente',
      nextPage: 'Page suivante',
      displayedRows: '{from}-{to} sur {count}'
    },
    xhrMaxItemCountReached:
      'Vous ne pouvez pas sélectionner plus de {XhrMaxItemCount} utilisateurs actuellement.',
    welcome: 'Bienvenue,',
    accountLabel: '{name} - {zipCode} {city} / {code}',
    switchAccount: 'Changer de compte client',
    disabledAccount: '(Désactivé)',
    tableHead: {
      name: 'Nom',
      status: 'Statut',
      active: 'Actif',
      code: 'Code',
      validationCode: 'Code de validation',
      expiration: 'Expiration'
    }
  },
  component: {
    modals: {
      createUser: {
        heading: 'Inviter des utilisateurs',
        submit: 'Inviter',
        wizard: {
          heading: 'Prenez en main vos utilisateurs',
          subheading:
            'Personnalisez et administrez facilement les données, les accès et les groupes de vos utilisateurs.'
        },
        labels: {
          emails: 'Adresses e-mail',
          emailsHelperText:
            'Ajouter plusieurs utilisateurs en utilisant la virgule. Maximum 100 utilisateurs.',
          accountCode: 'Code compte client'
        },
        invitationSent:
          'Vous avez envoyé {invitationsQuantity, plural, zero {{invitationsQuantity, number} invitation } one {{invitationsQuantity, number} invitation} other {{invitationsQuantity, number} invitations}}',
        invitationFailed: "Les invitations suivantes n'ont pas pu être créées :"
      }
    },
    form: {
      placeholder: {
        emailsInput: 'Ex: nom@entreprise.com, nom1@entreprise.com'
      },
      label: {
        emailInputCount: "Total d'adresses e-mails à ajouter: {emailsCount}"
      }
    },
    accessrights: {
      pendingRequest: {
        count: `{value, plural, zero { {value} demande } one { {value} demande } other { {value} demandes }} `,
        sentence: `{value, plural, zero { {value} demande } one { {value} demande} other { {value} demandes}} en attente`
      }
    },
    administratedAccountsSelector: {
      allAccounts: 'Tous les comptes',
      seeAll: 'Voir tous les comptes'
    },
    searchBars: {
      availabledResult: {
        noResultPrimary: 'Aucun résultat ne correspond à votre saisie',
        noResultSecondary:
          'Retrouvez un utilisateur par son nom, son prénom ou bien encore par son email.'
      },
      userSelectedSearchBar: {
        sentence: `{total, plural, one {{total} demande correspond à votre recherche} other {{total} demandes correspondent à votre recherche}}`
      },
      placeholder: {
        users: 'Rechercher des utilisateurs',
        services: 'Rechercher des services'
      }
    },
    table: {
      selectAll: 'Tout sélectionner ({count})',
      elementSelected: '{count} élément(s) sélectionné(s)'
    },
    tableServiceOwners: {
      remove: {
        confirmation:
          'Vous êtes sur le point de supprimer le propriétaire de service {name}',
        success: "L'utilisateur n'est plus propriétaire du service"
      }
    },
    editToggle: {
      editGroup: {
        update: {
          success: 'Le groupe a été mis à jour avec succès'
        }
      }
    },
    userAccountUpdate: {
      tooltip: "Modifier le compte client de l'utilisateur",
      unlink: `Désassocier du compte client {value}`,
      link: 'Associer à un autre compte client',
      unlinkDialogTitle:
        "Vous êtes sur le point de détacher l'utilisateur du compte client",
      irreversibleAction:
        'Cette action est irréversible. Souhaitez-vous continuer ?',
      linkDialogTitle:
        'Vous êtes sur le point de modifier le compte client associé à cet utilisateur',
      whichNewAccount: "A quel compte souhaitez-vous rattacher l'utilisateur ?",
      chooseAccount: 'Compte de rattachement',
      chooseLanguage: "Langue de l'invitation",
      lostAccessCount: `L'utilisateur perdra accès à {value, plural, one {{value} service} other {{value} services}}. `,
      inheritedServicesFromGroup: ` - (service hérité du groupe {value})`,
      alertInfo: {
        previousMembersList: `L'utilisateur n'apparaîtra plus dans la liste des utilisateurs du compte client {value}.`,
        previousAccountAccessRights: `L'utilisateur perdra ses accès aux services suivants :`,
        previousExternalisableAccessRights: `Si l'utilisateur possède toujours un accès sur un service
        externalisable de {value} avant votre confirmation, celui-ci sera conservé.`,
        newAccountMembersList: `L'utilisateur apparaîtra dans les membres du compte client {value}.`
      },
      alertWarning: {
        retainedAccessRights: `Les accès suivant seront conservés :`,
        unlinkGroups: `L'utilisateur sera détaché de tous les groupes du compte client.`,
        alertWarningTitle: 'Attention',
        noMoreAccessToProfile: `Vous ne pourrez plus accéder à ce profil utilisateur une fois
        qu'il sera détaché du compte client {value}.`,
        removeAllAccessWarning: `Si vous souhaitez retirer, tout ou partie, des accès de l'utilisateur {value} aux services nommés ci-dessus, veillez à les lui supprimer avant de le détacher du compte client. Vous pouvez effectuer cette action depuis sa liste de `,
        awareness:
          'Cette action est sensible. Soyez vigilant avant de confirmer.'
      },
      switchAccount: {
        newAffiliationAccount: `Nouveau compte client de rattachement`,
        dialogTitle: `Vous êtes sur le point de modifier le compte client associé à cet
        utilisateur. Souhaitez-vous continuer ?`,
        newInheritedServices: `L'utilisateur obtiendra automatiquement accès aux services
        par défaut de ce compte client.`,
        servicesListLink: `Voir la liste des services`,
        alertInfoTitle: `Pour le nouveau compte client de rattachement`
      },
      accessToProfileFromNewAccount: `Vous pourrez accéder à ce profil utilisateur à partir de ce compte client.`,
      forSuperUserOnly: {
        newAccessToProfile: `Vous pourrez accéder à ce profil utilisateur depuis la liste de tous `,
        administratedUsers: `les utilisateurs que vous administrez`
      },
      mutation: {
        linkToNewAccountSuccess: `L'utilisateur a été associé avec succès au compte client {value}.`,
        unLinkSuccess: `L'utilisateur a été détaché avec succès du compte client {value}.`
      }
    }
  },
  selectorList: {
    ipRangeType: {
      persons: 'Utilisateur',
      apiKey: 'Api Key'
    }
  },
  error: {
    noAccountSelected: 'Aucun compte client sélectionné',
    selectAnAccount:
      'Sélectionnez préalablement un compte client pour voir les détails de ce service.',
    somethingHappened: 'Une erreur est survenue',
    comeBackLater: 'Réessayer plus tard',
    oops: 'Oups.',
    copyText: 'Copier le texte',
    textCopied: 'Texte copié',
    details: 'Détails des erreurs',
    noDataAvailable: 'Aucune donnée disponible !',
    infoWillBeDisplayedHere: "Vos informations s'afficheront ici.",
    form: {
      invalidEmail: 'Cette adresse e-mail est invalide',
      invalidEmailsLength: "Le nombre maximal d'e-mails est de 100",
      emailsDuplication: 'Il y a au moins un email dupliqué',
      invalidIp: 'Vérifiez le format',
      invalidIpRange: 'Plage IP invalide',
      requiredField: 'Le champ est obligatoire',
      min: `La valeur minimal est {value}`,
      max: 'La valeur maximal est {value}',
      minLength: `Le texte est très court. Min. {value} caractères`,
      maxLength: `Le texte est très long. Max. {value} caractères`
    },
    type: {
      signin: 'Erreur de connexion',
      activation: "Erreur d'activation",
      requiredField: `Le champ est obligatoire.`,
      userAlreadyExists: `L'utilisateur existe déjà.`,
      network: `Une erreur réseau est survenue. Êtes-vous connecté à Internet ?`,
      batch: `L'action a été interrompue du fait de plusieurs erreurs.`,
      E101: `L'utilisateur existe déjà dans ce compte client.`,
      E104: `Plusieurs utilisateurs avec ces paramètres.`,
      E121: `La suppression de son propre profil n'est pas autorisé`,
      E123: `Un compte existe déjà avec cette adresse e-mail.`,
      E125: `L'adresse e-mail est obligatoire.`,
      E126: `Il n'est pas autorisé de supprimer le dernier utilisateur d'un compte.`,
      E139: `Vous n'êtes pas autorisé à supprimer ce profil`,
      E145: `Le domaine de l'e-mail est lié à un annuaire fédéré.`,
      E247: `La suppression en masse n'est pas autorisée`,
      E403: `Accès refusé`,
      E404: 'Page non trouvée',
      E500: `Une erreur est survenue, veuillez réessayer plus tard.`,
      HC0045: `Le serveur a mis trop de temps à répondre.`,
      HC0045A: `Demande en attente, veuillez actualiser le navigateur pour voir les résultats.`,
      invalidDateFormat: 'Le format de date est invalide',
      superUserAccessRefused: `Vous n'avez pas d'accès super utilisateur`
    },
    sentence: {
      emailChange: "L'email n'a pas pu être modifié",
      signin: "Une erreur s'est produite lors de votre tentative de connexion",
      activation:
        "Votre lien d'activation est expiré ou votre profil est déjà activé.",
      E403: "Vous n'êtes pas autorisé à accéder à ce contenu.",
      E404: 'La page demandée est introuvable.'
    },
    illustrationAlt: {
      ballons: `Illustration d'une maison en papier qui flotte grâce à des ballons`
    }
  },
  menu: {
    activities: 'Activités',
    apiKey: "Clés d'API",
    dashboard: 'Tableau de bord',
    groups: 'Groupes',
    management: 'Gestion',
    settings: 'Préférences',
    notifications: 'Notifications',
    services: 'Services',
    accessRights: "Demandes d'accès",
    users: 'Utilisateurs',
    validateRequest: 'Valider une demande',
    profileManager: "Revenir à l'ancienne version",
    profile: 'Profil',
    shortcuts: 'Raccourcis',
    addUser: 'Inviter des utilisateurs',
    addGroup: 'Ajouter un groupe',
    organizations: 'Organisations'
  },
  myMenu: {
    profile: 'Profil',
    logout: 'Déconnexion'
  },
  banner: {
    pendingRequest: `Votre délégation temporaire d'administration se termine dans {hours}h {minutes}mn`,
    bannerText: `Demander à un administrateur du compte la délégation temporaire d'administration.`,
    buttonText: `Demander une délégation d'administration`,
    accessRightRequestDialog: {
      title: `Demander l'accès à un administrateur`,
      noAdministratorYet:
        "Ce compte n'a pas encore d'administrateur. Veuillez contacter le support.",
      text: `Renseignez l'adresse e-mail d'un administrateur du compte client pour effectuer des modifications. Nous enverrons un e-mail de confirmation à l'administrateur afin qu'il valide votre demande.`,
      subtext: `L'e-mail contient un lien de validation valable 5 minutes. Une fois votre demande acceptée, vous bénéficiez d'un accès à l'administration du compte pour une durée de 2 heures.`,
      sendEmail: `Envoyer l'e-mail`,
      pendingRequest: `Un message a été envoyé à l'administrateur pour valider la demande de délégation.`,
      pendingRequestInfo: `L'e-mail envoyé à l'administrateur du compte contient un lien valable 5 minutes. Ce lien lui permet d'accepter votre demande. Suite à la validation de votre demande, vous bénéficiez d'un accès temporaire à l'administration du compte pour une durée de 2 heures.`
    }
  },
  delegation: {
    success: "Les droits d'administration ont été accordés avec succès.",
    error:
      "Les droits d'administration n'ont pas pu être ajoutés. Veuillez contacter le support."
  },
  onBoarding: {
    step1H5: 'Nous avons mis à jour la gestion de compte.',
    step1Subtitle1:
      'Découvrons certaines fonctionnalités de la nouvelle version.',
    step2H5: 'Visualisez simplement les informations importantes',
    step2Subtitle1:
      'Un tableau de bord synthétique vous permet de visualiser les informations principales des comptes que vous administrez.',
    step3H5: 'Gérez plus facilement les abonnements de vos utilisateurs.',
    step3Subtitle1:
      'Les listes vous permettent de sélectionner facilement plusieurs utilisateurs et services pour ouvrir rapidement des accès à vos utilisateurs.'
  },
  page: {
    accountProfile: {
      title: 'Compte {value}',
      youConsult: 'Vous consultez le compte client',
      aboutTitle: 'Informations administratives',
      membersSection: {
        membersTitle: 'Membres',
        members: 'utilisateur(s)',
        seeAllMembers: 'Voir tous les utilisateurs',
        notificationSettings: 'Préférence de notification',
        notificationSettingsTooltip:
          "Les utilisateurs sont notifiés par email lorsqu'un accès à un service leur est ajouté."
      },
      sections: {
        administrators: {
          title: 'Administrateurs',
          numberOfAdministrators: `Nombre d'administrateur(s)`,
          seeAllAdministrators: 'Voir tous les administrateurs'
        },
        about: {
          accountName: 'Nom du compte',
          accountCode: 'Code'
        },
        services: {
          servicesCatalog: 'Service(s) au catalogue',
          seeCatalog: 'Voir le catalogue'
        },
        apikeys: {
          title: "Clés d'API",
          activedApiKeys: 'Clés Actives',
          seeAll: "Voir toutes les clés d'Api"
        },
        groups: {
          title: 'Groupe(s)',
          seeAllGroups: 'Voir tous les groupes'
        },
        pendingRequests: {
          title: "Demande(s) d'accès en attente",
          manageRequests: 'Gérer les demandes'
        }
      }
    },
    apiKeyProfile: {
      info: {
        title: `Nom de la clé :`,
        subtitle: `ID : {value}`
      },
      technicalInformations: 'Informations techniques',
      contact: 'Interlocuteur',
      creator: 'Createur',
      creationDate: 'Date de création',
      revocationDate: 'Date de révocation',
      relatedServices: 'Le service associé',
      noRelatedServices: `Il n'y a pas de service associé à cette clé d'Api pour le moment`,
      service: 'Service',
      customerAccount: 'Compte client',
      activities: 'Activités',
      allActivities: 'Voir toutes les activités'
    },
    apikeyProfile: {
      menu: {
        general: 'Général',
        audit: 'Activités'
      },
      title: `Clé d'Api {uuid}`,
      activities: {
        title: 'Activités',
        type: `Type`,
        date: `Date`,
        author: `Auteur`
      },
      activity: {
        type: `{value, select, ${ApiKeyEventType.Creation} {Création} ${ApiKeyEventType.AccessGranted} {Accès autorisé} ${ApiKeyEventType.Revocation} {Révocation} ${ApiKeyEventType.AccessDenied} {Accès refusé}  other {-}}`,
        emptyStateTitle: "Il n'y a pas d'historique pour cette clé d'api",
        emptyStateText: 'Les prochains événements apparaîtront ici'
      }
    },
    changeEmailValidation: {
      title: "Validation de la demande de changement d'adresse email",
      subtitle:
        'Votre nouvelle adresse de connexion a été mise à jour avec succès : {email}',
      goToCegid: 'Aller sur Cegid Account'
    },
    accessRights: {
      newRequests: "Nouvelles demandes d'accès",
      title: "Demandes d'accès",
      subtitle:
        "Consultez et gérez les demandes d'accès aux services de vos utilisateurs",
      empty: {
        title: `Aucune demande d'abonnement`,
        subtitle: `Les prochaines demandes d'accès apparaîtront ici.`
      },
      acceptRequestSuccess: `Vous venez d'accepter la demande`,
      denyRequestSuccess: `Vous venez de refuser la demande`,
      updateRequestStatusSuccess: `La demande a bien été mise à jour`,
      changeExpirationDate: `Modifier`,
      cancelExpirationDate: `Annuler`
    },
    accountSelection: {
      title: 'Sélection du compte client',
      whichAccount: 'Sur quel <b>compte client</b> souhaitez-vous travailler ?',
      accountsFound: `{count, plural, zero {{count, number} compte client trouvé} one {{count, number} compte client trouvé} other {{count, number} comptes clients trouvés}}`,
      accountLabel: 'Code du compte client',
      loginToAccount: 'Entrer sur le compte',
      noAccountsFound: 'Aucun compte trouvé'
    },
    administrators: {
      title: 'Administrateurs',
      index: {
        administrators: 'Administrateurs',
        email: 'E-mail',
        allStatus: 'Etat',
        language: 'Langue'
      },
      accountAdministrators: 'Administrateurs de compte client',
      emptyState: {
        title: 'Aucun administrateur défini',
        description:
          'Il est important de définir un administrateur pour ce tier.'
      }
    },
    apiKeys: {
      title: `Clés d'API`,
      addApikeyTooltip:
        "Une api key associée à un service permet de donner un droit d'accès à un programme informatique.",
      requireAccountSelectionTooltip: `Sélectionnez préalablement un compte afin de générer une clé API.`,
      refreshTooltip: 'Mettre à jour la liste',
      apikeyHistory: `Activités de la clé {name}`,
      noApiKey: `Aucune clé d'API`,
      futureApiKeysText: `Les futures clés d'API générées apparaîtront ici.`,
      creator: `Créateur : {fullName}`,
      undefinedCreator: 'Créateur inconnu',
      undefinedCreationDate: 'Date de création inconnue',
      revoke: 'Révoquer',
      details: 'Détails',
      history: 'Activités',
      revocationConfirmationApiKeyName: `Vous allez révoquer la clé d'API `,
      revocationConfirmationText: `. Cette action sera définitive. Voulez-vous continuer ?`,
      revocationSuccessMessage: `La clé d'api {value} a été révoquée avec succès.`,
      revokedTitle: 'Révoquées',
      activeTitle: 'Actives',
      generate: {
        apiKey: `Générer une clé d'Api`,
        theApiKey: `Générer la clé d'Api`,
        selectService: `Sélectionner un service`,
        enterNewApiKeyName: `Entrer le nom d'une nouvelle clé`,
        apiKeyNameAlreadyExist: `Ce service dispose déjà d'une clé d'Api du même nom.`,
        mandatoryService: `Vous devez choisir un service.`,
        mandatoryName: `Vous devez saisir un nom pour cette nouvelle clé.`
      },
      success: {
        title: `Clé d'API générée avec succès`,
        yourApiKey: `Votre clé d'API`,
        apiKeySecret: `Secret de la clé d'API`,
        warning: `Le secret de la clé d'API générée ne pourra plus jamais être affichées.`
      }
    },
    createPersonGroup: {
      title: 'Ajouter un groupe',
      heading: 'Gérer plus facilement vos utilisateurs',
      subheading:
        'Vous pouvez classer les utilisateurs de votre entreprise dans des groupes. Les services associés aux groupes seront automatiquement ajouté à chaque utilisateur membre du groupe.',
      stepInformation: {
        name: 'Nom du groupe',
        description: 'Description',
        accountCode: 'Code compte client'
      },
      success: `Le groupe {groupName} a été ajouté avec succès`,
      groupAdded: '• Un groupe a été ajouté.'
    },
    dashBoard: {
      title: 'Tableau de bord',
      index: {
        accountList: 'Liste des comptes clients',
        administrators: 'Administrateurs',
        initializing: 'Initialiation',
        subscriptionRequests: {
          title: "Nouvelles demandes d'accès aux services",
          noPendingRequests: "Il n'y a pas de demandes d'accès en attente"
        },
        totalAdministrators: `{count, plural, one {{count, number} élément} other {{count, number} éléments}}`
      },
      disabledUser: "Cet utilisateur n'existe plus"
    },
    groupProfile: {
      title: 'Groupe {name}',
      youConsultGroup: 'Vous consultez le groupe',
      information: {
        title: 'Information'
      },
      removeGroup: {
        removeGroupConfirmation: `Vous êtes sur le point de supprimer le groupe {groupName}. Les membres actuels du groupe perdront leurs accès (via le groupe) aux services associés. Souhaitez-vous continuer ?`,
        removeSuccess: `Le groupe {groupName} a été supprimé avec succès.`
      },
      creationBy: ' par {creator}',
      editProfile: {
        title: 'Editer le groupe',
        name: 'Nom',
        description: 'Description'
      },
      services: {
        subscription: {
          removeConfirmation: `Vous êtes sur le point de supprimer le service {serviceName} du groupe {groupName}`,
          addServicesTitle: `Ajouter un/des service(s) au groupe`,
          deleteMessage: `Le service a été supprimé du groupe.`,
          successMessage: `{count, plural, one {{count, number} service a été ajouté au groupe.} other {{count, number} services ont été ajoutés au groupe.}} `
        },
        emptyState: {
          title: 'Aucun service associé !',
          description: 'Les futurs services apparaîtront ici.'
        }
      },
      addServices: {
        emptyState: {
          title: 'La liste est vide',
          description: 'Aucun service disponible'
        },
        selection:
          '{count, plural, one {{count, number} service a été sélectionné} other {{count, number} services ont été sélectionnés}}',
        completedSuccess:
          'Vous avez ajouté {count, plural, one {{count, number} service} other {{count, number} services}}.'
      },
      members: {
        add: 'Ajouter un membre',
        removeDialogTitle: 'Confirmation de suppression',
        removeMemberConfirmation: `Vous êtes sur le point de supprimer l'utilisateur {fullName} du groupe {groupName}`,
        removeSuccess: `L'utilisateur a été supprimé du groupe.`,
        pageAllSelected:
          '{count, plural, one {{count, number} membre a été sélectionné} other {{count, number} membres ont été sélectionnés}} sur cette page.',
        selectAll:
          'Sélectionner {count, plural, one {{count, number} membre} other {{count, number} membres}}',
        maxSelectedExceeded:
          'Votre sélection dépasse la limite maximum de {count, plural, one {{count, number} membre} other {{count, number} membres}}. Veuillez en sélectionner moins.',
        emptyState: {
          title: 'Aucun membre associé !',
          description: 'Les futurs membres apparaîtront ici.'
        },
        addMembers: {
          emptyState: {
            title: 'Aucun utilisateur ne peut être ajouter à ce groupe',
            description: 'Aucun membre disponible'
          },
          completedSuccess:
            'Vous avez ajouté {count, plural, one {{count, number} membre} other {{count, number} membres}}.',
          selection:
            '{count, plural, one {{count, number} membre a été sélectionné} other {{count, number} membres ont été sélectionnés}}'
        }
      }
    },
    groups: {
      title: 'Groupes',
      addGroupTooltip:
        "Créer des groupes pour gérer les droits d'accès d'un groupe de personnes",
      help:
        "Le groupe relie un ensemble d'utilisateurs à un ensemble de services.",
      index: {
        group: 'Groupe',
        account: 'Compte client'
      },
      emptyState: {
        title: 'Aucun groupe créé',
        description: 'Commencez par créer un nouveau groupe',
        superReaderDescription: 'Les groupes créés apparaîtront ici.'
      }
    },
    notifications: {
      title: 'Notifications',
      help:
        'Gérez les emails que vous et vos collaborateurs souhaitez recevoir de Cegid Account',
      subtitle: 'Sélectionnez les critères qui vous conviennent.',
      email: 'E-mail',
      newAccessRightsTitle: "Ajout d'un service",
      newAccessRightsDescription:
        "Notifiez vos utilisateurs lorsqu'ils ont accès à un nouveau service.",
      successfullySaved: `Vos préférences de notification ont bien été mises à jour pour le compte client {account}.`
    },
    organizationProfile: {
      pageTitle: 'Organisation {value}',
      youConsultSentence: "Vous consultez l'organisation",
      uniqueIdentifier: 'Identifiant unique',
      aboutTitle: 'Informations administratives',
      creationDate: 'Date de création',
      seeAllDomains: 'Voir les domaines',
      menu: {
        general: 'General',
        domains: 'Domaines'
      },
      countDomains: 'Domaines gérés'
    },
    organizations: {
      title: 'Organisations',
      noOrganizationFound: 'Aucune organisation trouvée'
    },
    organizationDomains: {
      title: "Domaines de l'organisation {value}",
      domainsHelp: `
        Pour qu'un domaine soit certifié auprès de nos services, nous vous demandons de suivre une procédure de vérification.
        Cette procédure a pour objectif de garantir votre légitimité sur le domaine en insérant un code de vérification dans vos enregistrements DNS (depuis votre gestionnaire de domaines).
        Il s'agit généralement du site sur lequel vous avez acheté votre domaine ou sur lequel vous hébergez votre site Web. 
        Copiez le code de vérification, mis à votre disposition depuis notre interface, pour le domaine concerné.
        Rendez-vous sur votre gestionnaire de domaine, dans les enregistrements DNS correspondant au domaine à vérifier ; 
        Créez un nouvel enregistrement TXT ; 
        Collez le code de validation. 
        Enregistrez votre nouvel enregistrement TXT.
        Un programme vérifiera la présence de ce code de vérification dans vos DNS et validera automatiquement ce domaine auprès de nos services.
      `,
      uniqueIdentifier: 'Identifiant unique {value}',
      domains: 'Domaines',
      statusType: `{value, select, ${DomainValidationMode.Strong} {Certifié} ${DomainValidationMode.None} {En attente de validation} ${DomainValidationMode.Weak} {A confirmer}}`,
      emptyState: 'Aucun domaine associé',
      startAddingDomain: 'Commencez par ajouter un domaine'
    },
    services: {
      title: 'Services',
      tableTitle: 'Catalogue de services',
      help:
        "Votre catalogue de services vous permet de visualiser l'ensemble des applications liées à votre contrat Cegid. Depuis ce tableau, vous pouvez modifier les propriétés d'accès à ces services, donner un droit d'accès à vos utilisateurs sur une ou plusieurs applications, déleguer l'administration d'un service  et en consulter le détail.",
      index: {
        family: 'Familles',
        selectedServiceCount: `{count, plural, zero {{count, number} service sélectionné} one {{count, number} service sélectionné} other {{count, number} services sélectionnés}}`,
        accountDistribution: '{label} • {serviceCount, number} services',
        fromAccount: ' (depuis {accountSubscriber})',
        visibleServices: 'Services visibles • {value}',
        notVisibleServices: 'Services non visibles • {value}'
      },
      action: {
        subscribeExternalUser: 'Abonner un utilisateur externe',
        subscribeExternalOwner: 'Ajouter un propriétaire externe',
        updateServicesProperties: 'Changer les propriétés des services',
        updateServicesPropertiesSuccess:
          'Les propriétés des services ont été mises à jour avec succès'
      },
      createSubscription: {
        title: 'Abonner un utilisateur',
        stepEmail: {
          email: 'E-mail',
          invalidEmail: 'Cette adresse e-mail est invalide.',
          checkEmail: `Vérifier l'adresse e-mail`,
          userDoesNotExist: "Cet utilisateur n'existe pas."
        },
        stepInfo: {
          checkInfo: 'Vérifier les informations'
        }
      },
      updateProperties: {
        title: 'Editer les services sélectionnés'
      },
      service: {
        availability: 'Disponibilité',
        info: 'Information'
      },
      table: {
        emptyStatePrimary: 'Aucun service trouvé',
      }
    },
    servicesIpRestrictions: {
      index: {
        title: "Ajouter une plage d'IP restrictive",
        tableTitle: 'Plages IPs autorisées',
        type: 'Type',
        description: 'Description',
        startIp: 'Ip de Début',
        endIp: 'Ip de Fin',
        enabled: 'Actif',
        disabled: 'Inactif',
        successAdd: 'La plage Ip a été ajoutée',
        successRemove: 'La plage Ip a été suprimée',
        successUpdate: 'La plage Ip a été modifiée'
      }
    },
    servicesMembers: {
      tableTitle: 'Utilisateurs ayant accès au service',
      emptyTitle: "Aucun utilisateur n'a accès au service",
      noAvailableUserTitle: 'Aucun utilisateur ne peut être ajouté au service',
      invite: 'Inviter',
      invitationResult: {
        external: "L'utilisateur a été invité au service",
        existing: 'Les utilisateurs ont été inscrits au service'
      },
      groups: {
        tableTitle: 'Groupes ayant accès au service',
        emptyTitle: "Aucun groupe n'a accès au service",
        noAvailableGroupTitle: 'Aucun groupe ne peut être ajouté au service',
        successAdd: 'Le groupe a été ajouté au service'
      }
    },
    externalserviceProfile: {
      title: `Nom du service :`,
      details: `{service} pour le compte de {targetAccount} `
    },
    serviceProfile: {
      settings: {
        title: 'Paramétrage du service'
      },
      serviceMembers: {
        alert: {
          title: `Nombre d'accès maximum`,
          accessLimit: `L'accès à ce service est limité à {value} membres.`,
          groupWarning:
            'Attention des groupes peuvent avoir accès à ce service et contenir eux-même plusieurs membres utilisateurs.'
        },
        add: 'Ajouter un membre',
        expirationDate: `Echéance`,
        removeExpiration: `Supprimer la date d'expiration`,
        changeExpirationDate: `Définir une date d'expiration`,
        expirationDateAlert: {
          accessUntil: `Accès jusqu'au : `,
          noLimitForOneAccessTitle:
            "L'accès à ce service ne sera plus limité dans le temps pour cet utilisateur.",
          noLimitForOneUserAndSeveralAccessTitle:
            "Les accès de l'utilisateur aux services sélectionnés ne seront plus limités dans le temps.",
          updateForOnServiceSeveralUsersTitle: `L'accès au service ne sera plus limité dans le temps pour votre sélection.`,
          expiryDateForOneUserSeveralServicesTitle:
            "L'utilisateur pourra accéder aux services sélectionnés jusqu'au ",
          selectedUserCanAccessTheServiceUntil: `{count, plural, one {L'accès sélectionné expirera} other {Les accès sélectionnés expireront}} le `,
          existingExpiryDateForOneUserSeveralAccess: `Une date d'expiration est déjà programmée pour un ou plusieurs accès parmis votre sélection.`,
          limitedAccessForOneUserAndOneService: `Le droit d'accès à ce service expirera le `
        },
        tableTitle: 'Utilisateurs ayant accès au service',
        emptyTitle: "Aucun utilisateur n'a accès au service",
        giveAccess: 'Donner accès au service',
        invite: 'Inviter',
        removeAccess: "Retirer l'accès au service",
        removeOneOrMoreAccess: `Retirer {count, plural, one {l'accès} other {les accès}} au service`,
        confirmRemove: `Vous allez supprimer l'accès au service {count, plural, one { de l'utilisateur sélectionné} other { des {count, number} utilisateurs sélectionnés}}. Souhaitez-vous continuer ?`,
        updateServiceEndDateForOneOrManyUser: `Choisissez la date d'expiration du service pour {count, plural, one { l'utilisateur sélectionné} other { les {count, number} utilisateurs sélectionnés}}.`,
        updateServicesEndDateForOneUser: `Choisissez la date d'expiration {count, plural, one { du service selectionné} other { des {count, number} services sélectionnés}} pour l'utilisateur.`,
        invitationResult: {
          external: "L'utilisateur a été invité au service",
          existing: 'Les utilisateurs ont été inscrits au service'
        },
        manageAccess: `Gérer l'accès`,
        remove: 'Retirer',
        updateEndDateResult: {
          one:
            "L'accès de cet utilisateur à ce service a été mis à jour avec succès",
          many:
            'Les accès au service pour ces utilisateurs ont été mis à jour avec succès'
        },
        removeResult: {
          one:
            "L'accès de cet utilisateur à ce service a été retiré avec succès",
          many: 'Les accès au service ont été retirés avec succès',
          manyServiceOneUser:
            'Les accès de cet utilisateur aux services sélectionnés ont été retirés avec succès'
        },
        groups: {
          tableTitle: 'Groupes ayant accès au service',
          emptyTitle: "Aucun groupe n'a accès au service",
          noAvailableGroupTitle: 'Aucun groupe ne peut être ajouté au service',
          successAdd: 'Le groupe a été ajouté au service'
        }
      },
      title: `Service - {service}`,
      youConsultService: `Vous consultez le service`,
      info: {
        title: `Nom du service : {value}`,
        defaultService: `Accès affecté à tous les utilisateurs par défaut`,
        adminValidation: `Accès au service soumis à validation d'un administrateur`
      },
      label: {
        defaultService: `Accès automatique`,
        adminValidation: `Validation administateur`,
        serviceCode: `Code du service`
      },
      aboutService: `A propos du service`,
      security: {
        formTitle: `Ajouter une plage d'IP autorisée`,
        formSubTitle: `{serviceName} / {accountName}`,
        descriptionPlaceholder: 'Ajouter une description',
        heading: 'Option de sécurité',
        subheading: `L'option de sécurité permet de restreindre l'accès à un service à une ou plusieurs plage(s) d'IP autorisée(s).`,
        addRange: 'Ajouter plage Ip',
        emptyTitle: "Il n'y a pas de restrictions",
        emptySubtitle: 'Utilisez le formulaire pour créer des restrictions'
      },
      subscribers: 'Abonnés',
      owners: {
        ownersTitle: 'Propriétaires',
        help:
          "Le propriétaire d'un service peut administrer le service, il peut ajouter/supprimer des membres et changer les propriétés du service",
        emptyTitle: `Ce service n'a pas encore de propriétaire`,
        owner: 'Propriétaire',
        add: 'Ajouter un propriétaire',
        sucess: 'Vous avez ajouté {ownersCount} propriétaires au service'
      }
    },
    userProfile: {
      title: 'Profil de {fullName} ',
      yourProfile: 'Votre profil',
      accountHelp: 'Numéro du compte client Cegid',
      acceptedServices: {
        help: "Regroupe l'ensemble des droits d'accès de l'utilisateur.",
        updateServiceEndDateForUser: `Choisissez la date de fin de validité au service pour l'utilisateur sélectionné`
      },
      deniedServices: {
        help:
          "Les demandes de droits d'accès suivantes ont été refusées par un administrateur."
      },
      suspendedServices: {
        help:
          "Les droits d'accès suivant ont été suspendus par un administrateur. Pour plus d'information contactez votre administrateur."
      },
      pendingServices: {
        help:
          "Ces demandes d'accès sont en attente de validation par un administrateur."
      },
      accountDisabledAlert: {
        title: 'Le compte est désactivé',
        message: 'Nous sommes désolés, ce compte n’est plus disponible.'
      },
      section: {
        account: {
          title: 'Compte'
        },
        administrators: {
          title: 'Administrateurs',
          noAdministrator: `{value} n'a pas encore d'administrateur. Veuillez contacter le support.`	
        },
        directories: {
          title: 'Annuaires'
        },
        personal: {
          title: 'Informations personnelles',
          fullname: 'Nom',
          password: 'Mot de passe',
          securityLevel: 'Niveau de sécurité du mot de passe : ',
          email: 'E-mail',
          pendingEmailRequest:
            "Demande de changement d'adresse e-mail en cours pour : {value}",
          phone: 'Téléphone',
          status: {
            activated: 'Activé',
            created: 'Créé',
            toActivate: 'A activer',
            disabled: 'Désactivé '
          }
        },
        technical: {
          technicalInformation: 'Informations techniques',
          immutableId: 'Immutable Id',
          immutableGuid: 'Immutable Guid',
          identifier: 'Identifiant : ',
          identityIdentifier: `Identifiant d'identité : `,
          tenantId: 'Tenant ID : ',
          objectId: 'Object ID : ',
          upn: 'UPN : '
        }
      },
      personalInformations: {
        activatedServices: `{value, plural, one {Service activé} other {Services activés} }`,
        pendingRequest: `{value, plural, zero {Demande en attente de validation} one {Demande en attente de validation} other {Demandes en attente de validation} } `,
        cancelRequest: 'Annuler la demande',
        cancelRequestConfirmationTitle: "Confirmation d'annulation",
        cancelRequestConfirmationText:
          "Etes-vous sûr de vouloir annuler la demande de changement d'e-mail en cours ?",
        cancelRequestSuccess: `La demande de changement d'adresse e-mail a été annulée`,
        renewRequest: `Renvoyer le mail`,
        resendRequestSuccess:
          'Un e-mail a été envoyé sur cette adresse e-mail pour confirmation.',
        activatedSince: 'Actif depuis le',
        userNotActivated: "En attente d'activation",
        seeAllServices: 'Voir tous les services activés'
      },
      lastActivities: {
        title: 'Activités récentes',
        activity: 'Activité',
        result: 'Résultat',
        date: 'Date',
        activityType: `{value, select, ${AuditAction.Signin} {Connexion} ${AuditAction.ProfileEdited} {Profil édité} ${AuditAction.PersonStatusChanged} {Statut modifié} ${AuditAction.MigrationToMicrosoft} {Migration vers Microsoft} ${AuditAction.PersonGroupCreation} {Groupe créé} other {-}}`,
        activityResult: `{value, select, ${AuditActionResult.Disabled} {Profil désactivé} ${AuditActionResult.SigninSucceeded} {Succès} ${AuditActionResult.PersonCreated} {Profil créé} ${AuditActionResult.SigninFailed} {Echoué} ${AuditActionResult.PersonDisabled} {Profil désactivé} ${AuditActionResult.PersonActivated} {Profil activé} ${AuditActionResult.PersonToActivate} {En attente d'activation} ${AuditActionResult.AuthenticationSucceeded} {Succès} ${AuditActionResult.Activated} {Activé} other {-}}`,
        allActivities: 'Voir toutes les activités'
      },
      activities: {
        title: 'Activités',
        filterLabel: 'Activité',
        activityType: `{value, select, ${ActivityType.All} {Tout} ${ActivityType.Creation} {Création} ${ActivityType.Update} {Modification} ${ActivityType.Connection} {Connexion} ${AuditAction.PersonGroupCreation} {Groupe créé} other {-}}`,
        emptyStateTitle: 'Aucune activité enregistrée pour le moment',
        emptyStateText: 'Les prochains événements apparaîtront ici.'
      },
      services: {
        service: 'Service',
        family: 'Famille',
        numberOfSubscribers: `{subscribers}`,
        numberOfLicenses: `{licenses}`,
        withValidation: 'Validation',
        administratedValidation:
          'Service nécessitant une validation par administrateur',
        notAdministratedValidation:
          'Service ne nécessitant pas de validation par un administrateur',
        auto: 'Par défaut',
        accessByDefault:
          'Service par défaut ajouté automatiquement aux utilisateurs',
        notAccessByDefault:
          'Service non-ajouté automatiquement aux utilisateurs',
        available: 'Disponible',
        unavailable: 'Indisponible',
        inheritedFromGroup: 'Hérité du groupe',
        accessToServiceSince: 'Accès au service depuis le ',
        expired: 'Expiré',
        refused: 'Refusé',
        suspendedLabel: 'Suspendu',
        suspended: `{value, plural, zero {Service suspendu} one {Service suspendu} other {Services suspendus} } `,
        toValidate: 'En attente de validation',
        add: 'Ajouter un accès',
        removeConfirmation:
          "Vous allez supprimer l'accès de l'utilisateur à {count, plural, one {1 service} other {{count, number} services}}, souhaitez-vous continuer ?",
        removeSuccess: `Vous vous êtes désabonné(e) de {count} services.`,
        pageAllSelected:
          '{count, plural, one {{count, number} service a été sélectionné} other {{count, number} services ont été sélectionnés}} sur cette page.',
        selectAll:
          'Sélectionner {count, plural, one {{count, number} service} other {{count, number} services}}',
        maxSelectedExceeded:
          'Votre sélection dépasse la limite maximum de {count, plural, one {{count, number} service} other {{count, number} services}}. Veuillez en sélectionner moins.',
        completedPending:
          "{count, plural, one {Votre demande d'ajout à {count, number} service a été envoyée} other {Vos demandes d'ajout à {count, number} services ont été envoyées}}.",
        pendingServices: 'En attente de validation',
        authorizedAccess: `{value, plural, zero {Accès autorisé} one {Accès autorisé} other {Accès autorisés} } `,
        deniedServices: `{value, plural, zero {Accès refusé} one {Accès refusé} other {Accès refusés} } `,
        suspendedAccess: `{value, plural, zero {Accès suspendu} one {Accès suspendu} other {Accès suspendus} } `,
        emptyStateTitle: 'Aucun service trouvé',
        emptyStateText:
          "Les droits d'accès aux services autorisés apparaîtront ici.",
        emptyState: {
          title: 'Aucune demande en attente.',
          description:
            "Les demandes d'accès à un ou plusieurs services apparaîtront ici."
        },
        subscriptionEndDate: "Accès jusqu'au {date} - {hour}",
        filterLabel: 'Statut',
        statusType: `{value, select, ${PersonAccessRightStatus.Accepted} {Autorisé(s)} ${PersonAccessRightStatus.ToValidate} {En attente de validation} ${PersonAccessRightStatus.Refused} {Refusé(s)} other {Suspendu(s)}}`
      },
      editName: {
        firstName: 'Prénom',
        lastName: 'Nom',
        editName: 'Changer de nom'
      },
      editPhone: {
        phoneNumber: 'Téléphone',
        mobileNumber: 'Téléphone Mobile',
        editPhone: 'Changer de n° de téléphone'
      },
      editLanguage: {
        language: 'Langue',
        editLanguage: 'Changer de langue'
      },
      editSecurityLevel: {
        securityLevel: 'Niveau de sécurité',
        editSecurityLevel: 'Changer de niveau de sécurité'
      },
      editEmail: {
        editEmail: "Changer d'adresse e-mail",
        email: 'E-mail',
        confirmation: {
          title: 'E-mail envoyé',
          message: `Un e-mail a été envoyé à l'adresse e-mail {email} avec pour sujet "Confirmation du changement d'e-mail". Cliquez sur le lien qu'il contient afin de confirmer le changement d'adresse e-mail.`,
          hint: `Si vous ne trouvez pas l'e-mail, vérifiez le dossier indésirable. Si vous ne trouvez toujours pas l'e-mail, vérifiez que la nouvelle adresse e-mail est correcte et réessayez.`
        }
      },
      editPassword: 'Modifier le mot de passe',
      updatedProfile: 'Le profil a été mis à jour avec succès',
      identityMerged: `Votre compte a bien été migré, vous allez devoir vous authentifiez avec vos identifiants {dir}.`,
      beforeDirectoryMergeTitle: `Vous allez être redirigé vers la page de connexion {dir}`,
      beforeDirectoryMergeConditions: `Pour basculer votre compte Cegid vers votre compte {dir}`,
      beforeDirectoryMergeText: `Vous devez vous connecter avec vos identifiants {dir} existants. L'adresse e-mail utilisée peut être la même que celle de votre compte Cegid.`,
      mergeAccountInfo: `Vous souhaitez vous identifier via un compte {dir}`,
      mergeAction: `Lier un compte {value}`,
      yourInformation: 'Vos informations',
      mergeValidationText: `Après la migration, la connexion s'effectuera avec vos identifiants {dir} pour l'ensemble des applications Cegid.`,
      mergeValidationSubText:
        'Pour synchroniser votre compte {dir}, il vous suffit de vous reconnecter.'
    },
    personGroupProfile: {
      tabServices: {
        addServiceButton: 'Ajouter un service'
      },
      about: {
        title: 'A propos du groupe'
      }
    },
    users: {
      title: 'Utilisateurs',
      help:
        "Depuis cette liste, vous pouvez sélectionner un ou plusieurs utilisateurs afin de les révoquer, les exporter (format excel) ou bien leur octroyer des droits d'accès sur une ou plusieurs applications (services de votre catalogue).",
      name: 'Nom',
      firstname: 'Prénom',
      action: {
        addUser: 'Inviter des utilisateurs',
        delete: {
          confirmation: `Vous allez supprimer {count, plural, one {{count, number} utilisateur} other {{count, number} utilisateurs}}, souhaitez-vous continuer ?`,
          success: `{count, plural, one {{count, number} utilisateur a été supprimé.} other {{count, number} utilisateurs ont été supprimés.}}`
        }
      },
      table: {
        emptyStatePrimary: 'Aucun utilisateur trouvé',
      },
      pageSelectedUserCount:
        '{count, plural, one {{count, number} utilisateur de cette page a été sélectionné} other {{count, number} utilisateurs de cette page ont été sélectionnés}}.',
      selectUserCount:
        'Sélectionner {count, plural, one {{count, number} utilisateur} other {{count, number} utilisateurs}}',
      maxLimitExceeded:
        'Votre sélection dépasse la limite maximum de {count, plural, one {{count, number} utilisateur} other {{count, number} utilisateurs}}. Veuillez en sélectionner moins.',
      index: {
        administrators: 'Administrateur',
        users: 'Utilisateur',
        email: 'E-mail',
        account: 'Compte client',
        creationDate: 'Date de création',
        activationDate: "Date d'activation",
        language: 'Langue',
        phoneNumber: 'Téléphone',
        mobileNumber: 'Téléphone mobile',
        titleCode: 'Civilité',
        allGroups: 'Tous les groupes',
        allRoles: 'Tous les rôles',
        allStatus: 'Etat',
        item: {
          email: '{value, select, null { } other {{value}} }',
          group: '{value, select, TOCLASSIFY {À Classer} other { }}',
          role: '{value, select, null { } other {{value}} }',
          status:
            '{value, select, CREATED {Créé} TOACTIVATE {À activer} ACTIVATED {Actif} DISABLED {Inactif} }'
        },
        selectedUserCount: `{count, plural, one {{count, number} utilisateur sélectionné} other {{count, number} utilisateurs sélectionnés}}`,
        userStatus: {
          actives: 'Actifs • {count, number}',
          pending: "En attente d'activation • {count, number}",
          created: 'Créés • {count, number}'
        }
      },
      subscription: {
        success: {
          meToOne: `Vous vous êtes abonné(e) à 1 service.`,
          meToMany: `Vous vous êtes abonné(e) à {servicesCount} services.`,
          oneToOne: `L'utilisateur a été abonné au service.`,
          oneToMany: `L'utilisateur a été abonné à {servicesCount} services.`,
          manyToOne: `{usersCount} utilisateurs ont été abonnés au service.`,
          manyToMany: `{usersCount} utilisateurs ont été abonnés à {servicesCount} services.`
        }
      },
      export: {
        lists: 'Listes',
        columns: 'Colonnes',
        help:
          'Sélectionnez uniquement les colonnes que vous souhaitez exporter.',
        needServiceList:
          'Sélectionnez uniquement les listes que vous souhaitez exporter.',
        itemToExport: 'Eléments à exporter',
        withServiceList: 'Liste des services par utilisateur',
        accountCode: 'Code du compte client',
        accountLabel: 'Libellé du compte client',
        creationDate: 'Date de création',
        email: 'E-mail',
        securityLevel: 'Niveau de sécurité',
        status: 'Etat',
        services: 'Liste des services par utilisateurs'
      }
    }
  },
  profile: {
    menu: {
      access: 'Accès',
      general: 'Général',
      services: 'Services',
      restrictions: 'Restrictions',
      activities: 'Activités',
      members: 'Membres',
      domains: 'Domaines'
    },
    about: {
      title: 'À propos',
      creationDate: 'Création',
      directories: 'Annuaires',
      language: 'Langue',
      outsourceableService: {
        title: 'Service externalisable'
      },
      serviceFamily: 'Famille de service'
    }
  },
  securityLevel: {
    weak: 'Faible',
    medium: 'Moyen',
    high: 'Fort'
  },
  tableCollectionColumnSelection: {
    title: 'Option de colonnes',
    hint:
      "Sélectionnez les colonnes à afficher et faites les glisser pour les réorganiser. Il se peut que toutes les colonnes ne s'affichent pas sur les petits écrans."
  },
  about: {
    google: {
      title: 'Se connecter à Cegid Cloud avec mon compte Google',
      subtitle:
        "Cegid Cloud correspond à l'écosystème d'applications et de services en ligne de Cegid",
      connectToCegidProducts: `Vous pouvez vous connecter à la plateforme Cegid Cloud et à l'ensemble des services qu'elle supporte à l'aide de votre compte Google.`,
      followSteps: `Pour cela il vous suffit de suivre les étapes selon votre besoin :`,
      whatYouWant: 'Que souhaitez-vous faire ?',
      connectToCegidApplicationWithYourGoogleAccount:
        'Associer votre compte Cegid existant à votre compte Google',
      activateYourCegidAccountWithGoogle:
        'Activer votre nouveau compte Cegid avec votre compte Google',
      youAlrearyHaveACegidAccount:
        'Vous avez déjà un compte Cegid et souhaitez vous identifier via votre compte Google : Fusionnez votre compte !',
      youNeedHelpWriteTo: `Si vous avez besoin d'aide pour activer ou connecter votre compte Cegid à votre compte Google, vous pouvez écrire à `,
      forInformation: `Pour information, vous pouvez retrouver`,
      ourCGV: `nos conditions générales d'utilisation `,
      andOur: `ainsi que notre`,
      privacyPolitic: `politique de confidentialité et cookie`,
      userActivation: {
        youReceiveAnEmailToActivateYourAccount:
          "Vous avez reçu un mail d'activation de compte Cegid et souhaitez utiliser les identifiants de votre compte Google lors de la connexion aux applications Cegid ?",
        step1Title: '1. Vous avez reçu un email',
        step1Content:
          "Cliquez sur le lien d'activation pour poursuivre la procédure.",
        step2Title: "2. Vous êtes redirigé sur la page d'activation de compte",
        step2Content: `Vous pouvez activer votre compte Cegid en utilisant votre compte Google en cliquant sur le bouton "Continuer avec Google"`,
        step3Title:
          "3. Vous êtes redirigé sur la page d'authentification Google.",
        step3Content: 'Saisissez les identifiants de votre compte Google.',
        step4Title: "4. Vous êtes redirigé sur l'interface Cegid Compte",
        step4Content: 'Votre compte a été activé avec succès.',
        step4Success:
          'Vous devez à présent être connecté à Cegid Compte et accéder à vos informations.'
      },
      userUpdateIdp: {
        step1:
          "1. Connectez-vous à l'application Cegid Compte et accéder à votre profil",
        step2:
          '2. Depuis la section annuaires, vous pouvez lier votre compte Google',
        step3: `3. Cliquez sur le bouton "Lier un compte Google", une information vous est alors indiquée. Cliquez sur "J'ai compris" pour être redirigé sur un écran de connexion Google.`,
        step4:
          '4. Saisissez votre email lié au compte Google que vous souhaitez utiliser pour vous connecter aux applications Cegid',
        step5: `5. Vérifiez vos informations, pour poursuivre la fusion de compte, cliquez sur "Migrer"`,
        step5End: `Vous allez être déconnecté de l'application.`,
        step6: `6. Identifiez-vous avec l'email du compte Google que vous venez de lier à votre profil Cegid.`,
        step7: `Vous devriez à présent être connecté sur votre profil avec vos informations mises à jour.`
      }
    }
  },
  helperText: {
    groupNameRequired: 'Le nom du groupe est obligatoire',
    groupDescriptionRequired: 'La description du groupe est obligatoire'
  },
  dataPrivacy: {
    title: 'Politique de protection des données',
    userProfileDataPrivacy:
      "Cegid SAS traite vos données personnelles dans le cadre la gestion de votre compte Cegid Account. Vous disposez des droits d'accès, de rectification, de suppression et de portabilité de vos données, ainsi que d'un droit d'opposition et de limitation à certains traitements. Pour plus d'informations sur le traitement et les modalités d'exercice de vos droits, vous pouvez consulter la politique de protection des données.",
    sentence1:
      "Les informations personnelles renseignées dans Cegid Account sont traitées par Cegid SAS afin de vous permettre d'accéder au services Cegid dans le cadre de la relation contractuelle. ",
    sentence2:
      "Vos données peuvent être communiquées aux filiales du responsable de traitement ainsi qu'à des prestataires externes en charge de la mise en œuvre du traitement ou à des tiers autorisés dans le cas de requêtes par les autorités compétentes. Il est nécessaire de fournir un identifiant (courriel ou matricule notamment). A défaut, le traitement ne pourra pas être effectué.",
    sentence3:
      'Vos données personnelles sont conservées {value} ans à partir de votre dernière activité enregistrée sur le compte (votre dernière connexion par exemple).',
    sentence4:
      "Vos données personnelles peuvent faire l'objet de transferts vers des pays situés en dehors de l'Union Européenne pour les finalités détaillées ci-dessus. Ces transferts font l'objet d'un encadrement juridique spécifique afin que ces données soient couvertes par un niveau de protection adéquat.",
    sentence5:
      "Dans le respect de la réglementation applicable en matière de protection des données à caractère personnel, vous disposez d'un droit d'accès, de rectification, de suppression et de portabilité de vos données, ainsi que d'un droit d'opposition et de limitation à l'ensemble des données vous concernant.",
    sentence6:
      "Ces droits s'exercent en écrivant à notre délégué à la protection des données en envoyant votre demande à dataprivacy@cegid.com. Vous pouvez à tout moment exercer votre droit de recours auprès de l'Autorité compétente en matière de protection des données personnelles (CNIL)."
  }
});
